import { NgZone } from '@angular/core';
import { from, Observable, Subject } from 'rxjs';

/**
 * Ensures that the given async function resolves inside Angular's zones.
 *
 * This is critical in order for change detection to work.
 *
 * @param zone Angular's NgZone
 * @param fn   The async function to execute
 */
export function deferToZone<T>(zone: NgZone, fn: () => Promise<T>): Observable<T> {
    const subject = new Subject<T>();

    fn().then((...args) => zone.run(() => {
        subject.next(...args);
        subject.complete();
    }))
        .catch((e) => zone.run(() => subject.error(e)));

    return from(subject);
}
