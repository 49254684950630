import { MonoTypeOperatorFunction, Observable, SchedulerLike, throwError, timer } from 'rxjs';
import { mergeMap, retryWhen } from 'rxjs/operators';

export function retryAfter<T>(delay: number,
    maxRetryAttempts?: number,
    scheduler?: SchedulerLike): MonoTypeOperatorFunction<T> {
    return (input: Observable<T>) => input.pipe(
        retryWhen(
            (errors) => errors.pipe(
                mergeMap((error, i) => {
                    const retryAttempt = i + 1;

                    if (!!maxRetryAttempts && retryAttempt > maxRetryAttempts) {
                        return throwError(error);
                    }

                    // For every error occurring in the source, retry after "delay" time
                    return timer(delay, null, scheduler);
                }),
            ),
        ),
    );
}
