import { Activity } from '@app/shared/models/activity.model';
import { Event } from '@app/shared/models/event.model';
import { Exclude, Expose, Type } from 'class-transformer';

@Exclude()
export class Page<T> {

    @Expose({ name: 'hydra:member' })
    @Type(() => null, {
        discriminator: {
            property: '@type',
            subTypes: [
                { value: Activity, name: 'Activity' },
                { value: Event, name: 'Event' },
            ],
        },
    })
        items: T[];

    @Expose({ name: 'hydra:totalItems' })
    readonly totalCount: number;

    @Expose({ name: 'hydra:view' })
    set view(view: any) {
        if (view && view['hydra:last'] && view['hydra:first']) {
            const pageMatch = view['@id'].match(/page=(\d+)/);
            this._number = pageMatch ? +pageMatch[1] : 1;
            this._hasNext = view['@id'] !== view['hydra:last'];
            this._hasPrevious = view['@id'] !== view['hydra:first'];
        } else {
            this._number = 1;
            this._hasNext = false;
            this._hasPrevious = false;
        }
    }

    get number(): number {
        return this._number;
    }

    get hasNext(): boolean {
        return this._hasNext;
    }

    get hasPrevious(): boolean {
        return this._hasPrevious;
    }

    private _number: number;

    private _hasNext: boolean;

    private _hasPrevious: boolean;

}
