import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FaqModel } from '@app/shared/models/faq.model';
import { isNotNull } from '@webmozarts/types';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class FaqService {

    constructor(private http: HttpClient) { }

    getFaqData(faqAreaUrl: string): Observable<FaqModel[]> {
        return this.http.get(faqAreaUrl).pipe(
            map((data: Array<null|Object>) => plainToInstance(FaqModel, data.filter(isNotNull))),
        );
    }
}
