import { Pipe, PipeTransform } from '@angular/core';
import sanitizeHtml, { IOptions } from 'sanitize-html';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

const COLOR_PATTERNS = [
    /^#(0x)?[0-9a-f]+$/i,
    /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/,
];

const DEFAULT_CONFIG: IOptions = {
    allowedTags: [
        'a',
        'br',
        'em',
        'li',
        'ol',
        'p',
        'strong',
        'span',
        'u',
        'ul',
    ],
    allowedAttributes: {
        span: ['style'],
        a: ['href', 'title', 'target'],
    },
    allowedStyles: {
        span: {
            // Match HEX and RGB
            'color': COLOR_PATTERNS,
            'background-color': COLOR_PATTERNS,
        },
    },
};

@Pipe({ name: 'sanitize' })
export class SanitizePipe implements PipeTransform  {
    constructor(private sanitizer: DomSanitizer) {
    }

    transform(value: string): SafeHtml {
        const safeHtml = sanitizeHtml(value, DEFAULT_CONFIG);

        return this.sanitizer.bypassSecurityTrustHtml(safeHtml);
    }
}
