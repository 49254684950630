import { Avatar } from '@app/shared/models/avatar.model';
import { Type } from 'class-transformer';

export class User {

    id: string;

    displayName: string;

    email: string;

    phoneNumber: string;

    firebaseUserId: string;

    @Type(() => Avatar)
        avatar?: Avatar;
}
