import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/core/services/authentication/authentication.service';
import * as Sentry from '@sentry/angular';
import { User } from 'firebase/auth';

function setSentryUser(user: User): void {
    const sentryUser = null !== user
        ? { email: user.email }
        : null;

    Sentry.setUser(sentryUser);
}

@Injectable({
    providedIn: 'root',
})
export class SentryScopeSetter {

    constructor(private authenticationService: AuthenticationService) {
    }

    initialize(): void {
        this.authenticationService.getViewer().subscribe(setSentryUser);
    }
}
