export const environment = {
    appApiUrl: 'https://staging-crm.neos.eu/api/app/v1',
    appUrl: 'https://staging-go.junos.at',
    entityId: '5911a3a7-b144-4de8-84db-6580d4ef4950',
    environment: 'staging',
    client: 'junos',
    faqSource : 'https://app-data.neos.eu/junos-faq.json',
    firebaseAccount: 'neos-staging',
    firebaseApiKey: 'AIzaSyARggFLutsbdyPWze1HDkf7dF89ybAKAdU',
    firebaseAppId: '1:614949948346:web:79fc2a4eedb6c34fb56b83',
    firebaseAuthDomain: 'neos-2-go-staging.firebaseapp.com',
    firebaseDatabaseURL: 'https://neos-2-go-staging.firebaseio.com',
    firebaseMessagingSenderId: '614949948346',
    firebaseProjectId: 'neos-2-go-staging',
    firebaseStorageBucket: 'neos-2-go-staging.appspot.com',
    googleMapsApiKey: 'AIzaSyARggFLutsbdyPWze1HDkf7dF89ybAKAdU',
    language: 'de',
    notificationsEnabled: false,
    preferredCountryCodes: ['AT', 'DE', 'CH'],
    privacyTermsUrl : 'https://junos.at/deine-daten-i-datenschutz/',
    production: true,
    provinceFilterEnabled: true,
    publicApiUrl: 'https://staging-crm.neos.eu/api/public/v1',
    releaseVersion: 'develop/next',
    sentryDsn: 'https://11d56fafe5bc4037bb67f6856dd0f356@sentry.webmozarts.com/11',
    sentryEnvironment: 'staging',
    sentryShowErrorDialog: false,
    sentryTracesSampleRate: 1.0,
    sentryRelease: '2go@57.0',
    validatedCountryCode: 'AT',
    defaultLongitude: 13.539224,
    defaultLatitude: 47.704637,
    defaultZoomLevel: 11,
    defaultMapRadius: 15,
    defaultListRadius: 100,
    phoneNumberMandatory: true,
    salutationMandatory: true,
    appProfileAddressLines: true,
    feedbackEmail: 'kontakt@junos.at',
    twitterIntegrationEnabled: true,
    twitterUsername: 'junos_at',
    facebookIntegrationEnabled: true,
    facebookAppId: '659691021606479',
    appLinkTracking: null,
};
