import { Injectable, Injector } from '@angular/core';
import { AuthenticationService } from '@app/core/services/authentication/authentication.service';
import { EnvironmentService } from '@app/core/services/environment-service';
import { isNotNull } from '@webmozarts/types';
import { repeatWhen } from '@app/shared/utils/rxjs/repeatWhen';
import { Platform } from '@ionic/angular';
import {
    EMPTY,
    MonoTypeOperatorFunction, Observable, ReplaySubject,
} from 'rxjs';
import {
    catchError, distinctUntilChanged, filter, first, scan, switchMap, tap,
} from 'rxjs/operators';
import {
    BaseMessagingService,
    HasTargetUrl,
    MessagingPlatform,
    NotificationPermission,
} from '@app/core/services/messaging/messaging-platform';
import { DeviceService } from '@app/core/services/messaging/device.service';
import { AppLifecycleService } from '@app/core/services/app-lifecycle/app-lifecycle.service';
import { WebMessaging } from '@app/core/services/messaging/web-messaging';
import { Logger } from '@app/core/services/logger/logger';
import { CapacitorFirebaseMessaging } from './capacitor-firebase-messaging.service';

interface TokenPair {
    previousToken: string|null;
    currentToken: string|null;
}

const INITIAL_TOKEN_PAIR: TokenPair = {
    previousToken: null,
    currentToken: null,
};

function didTokenChangeOperator(): MonoTypeOperatorFunction<TokenPair> {
    return filter((tokenPair) => tokenPair.previousToken !== tokenPair.currentToken);
}

@Injectable({
    providedIn: 'root',
})
export class MessagingService implements BaseMessagingService {

    private messagingPlatform: MessagingPlatform;

    private permissionGrantedSubject = new ReplaySubject<boolean>(1);

    private registered = false;

    readonly permissionGranted: Observable<boolean> = this.permissionGrantedSubject;

    constructor(
        injector: Injector,
        private authenticationService: AuthenticationService,
        platform: Platform,
        private deviceService: DeviceService,
        private appLifecycleService: AppLifecycleService,
        private logger: Logger,
        environmentService: EnvironmentService,
    ) {
        if (!environmentService.environment.notificationsEnabled) {
            return;
        }

        this.messagingPlatform = platform.is('capacitor')
            ? injector.get(CapacitorFirebaseMessaging)
            : injector.get(WebMessaging);

        // Make sure to delete the device instantly on logout instead of waiting for the messaging platform
        // because otherwise the API call can't be authenticated anymore.
        this.authenticationService.willSignOut
            .pipe(
                switchMap(() => this.messagingPlatform.getToken().pipe(first())),
                filter(isNotNull),
                switchMap((deviceToken) => this.tryDeleteDevice(deviceToken)),
            )
            .subscribe();

        this.initializeDeviceTokensSynchronizationWithApi();
        this.authenticationService.isAuthenticatedAndEmailVerified()
            .pipe(
                distinctUntilChanged(),
                repeatWhen(this.appLifecycleService.resume),
                switchMap((authenticatedAndEmailVerified) => this.enableDisableDeviceNotificationsOnViewerChange(authenticatedAndEmailVerified)),
            )
            .subscribe();
    }

    getCurrentMessage(): Observable<unknown> {
        return this.messagingPlatform.getCurrentMessage();
    }

    getNotificationClick(): Observable<HasTargetUrl|unknown> {
        return this.messagingPlatform.getNotificationClick();
    }

    private enableDisableDeviceNotificationsOnViewerChange(authenticatedAndEmailVerified: boolean): Promise<void> {
        return authenticatedAndEmailVerified
            ? this.tryEnableDeviceNotifications()
            : this.disableDeviceNotifications();
    }

    private async tryEnableDeviceNotifications(): Promise<void> {
        const permission = await this.requestPermission();

        this.logger.log(`Requested permissions to enable device notification. Got: "${permission}".`);

        const permissionGranted = 'granted' === permission;

        if (permissionGranted) {
            await this.register();
        } else {
            return this.disableDeviceNotifications();
        }
    }

    private disableDeviceNotifications(): Promise<void> {
        return this.unregister();
    }

    private async requestPermission(): Promise<NotificationPermission> {
        const permission = await this.messagingPlatform.requestPermission();

        this.permissionGrantedSubject.next('granted' === permission);

        return permission;
    }

    private async register(): Promise<void> {
        if (!this.registered) {
            this.registered = await this.messagingPlatform.register();
        }
    }

    private async unregister(): Promise<void> {
        if (this.registered) {
            const didUnregister = await this.messagingPlatform.unregister();
            this.registered = !didUnregister;
        }
    }

    private initializeDeviceTokensSynchronizationWithApi() {
        const newTokenPair$: Observable<TokenPair> = this.messagingPlatform.getToken()
            .pipe(
                tap((token) => this.logger.log(`Received the Firebase device token "${token}".`)),
                scan(
                    (lastTokenPair, currentToken: string|null) => ({
                        previousToken: lastTokenPair.currentToken,
                        currentToken,
                    }),
                    INITIAL_TOKEN_PAIR,
                ),
                didTokenChangeOperator(),
            );

        newTokenPair$
            .pipe(
                this.deletePreviousDeviceTokenOperator(),
                repeatWhen(this.appLifecycleService.resume),
                this.registerNewDeviceTokenOperator(),
                tap(() => this.logger.log('Synchronized the Firebase device token pair with the API.')),
            )
            .subscribe();
    }

    private deletePreviousDeviceTokenOperator(): MonoTypeOperatorFunction<TokenPair> {
        return tap(({ previousToken }) => {
            this.tryDeleteDevice(previousToken).subscribe();
        });
    }

    private tryDeleteDevice(token: string|null): Observable<unknown> {
        if (null === token) {
            return EMPTY;
        }

        return this.deviceService
            .deleteDevice(token)
            .pipe(catchError(() => EMPTY));
    }

    private registerNewDeviceTokenOperator(): MonoTypeOperatorFunction<TokenPair> {
        return tap(({ currentToken }) => {
            if (null === currentToken) {
                return;
            }

            this.deviceService
                .registerDevice(currentToken)
                .pipe(catchError(() => EMPTY))
                .subscribe();
        });
    }
}
