import { Entity } from '@app/shared/models/entity.model';
import { EventImage } from '@app/shared/models/event-image.model';
import { EventRegistration } from '@app/shared/models/event-registration.model';
import { EventType } from '@app/shared/models/event-type.model';
import { Place } from '@app/shared/models/place.model';
import { dateToString } from '@app/shared/transformers/dateToString';
import { Transform, Type } from 'class-transformer';

export class Event {

    id: string;

    title: string;

    text: string;

    @Type(() => EventImage)
        image: EventImage;

    @Type(() => Place)
        place: Place;

    @Type(() => EventType)
        eventType: EventType;

    numberOfAttendees: number;

    maximumNumberOfAttendees: number;

    @Type(() => Entity)
        entity: Entity;

    canceled: boolean;

    featured: boolean;

    @Transform(dateToString)
        startTime: Date;

    @Transform(dateToString)
        endTime: Date;

    distanceInKm: number;

    @Type(() => EventRegistration)
        registration: EventRegistration;

    registrationOpen: boolean;

    @Transform(dateToString)
        registrationDeadline: Date;

    registrationDeadlineReached: boolean;

    guestsPossible: boolean;

    get freeSpots(): number {
        return this.maximumNumberOfAttendees - this.numberOfAttendees;
    }

}
