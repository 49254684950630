import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@app/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class DeviceService {
    constructor(
        private client: HttpClient,
    ) {
    }

    registerDevice(token: string): Observable<object> {
        return this.client.post(environment.appApiUrl + `/devices/${token}`, null);
    }

    deleteDevice(token: string): Observable<object> {
        return this.client.delete(environment.appApiUrl + `/devices/${token}`);
    }
}
