import { Activity } from '@app/shared/models/activity.model';
import { Address } from '@app/shared/models/address.model';
import { Avatar } from '@app/shared/models/avatar.model';
import { UserRole } from '@app/shared/models/user-role.model';
import { User } from '@app/shared/models/user.model';
import { enumToString } from '@app/shared/transformers/enumToString';
import { Transform, Type } from 'class-transformer';
import { Salutation } from './salutation.model';

export class Profile {

    id: string;

    userId: string;

    contactId: string;

    @Transform(enumToString(Salutation))
        salutation: Salutation | null;

    firstName: string;

    lastName: string;

    phoneNumber: string;

    @Type(() => Address)
        address: Address;

    @Type(() => Avatar)
        avatar: Avatar;

    latitude: number;

    longitude: number;

    customGeolocationSet: boolean;

    emailVerified: boolean;

    termsAccepted: boolean;

    roles: UserRole[];

    calendarUrl: string;

    notificationChannelIds: string[];

    customFields: [] | {[key: string]: unknown};

    constructor(props = {}) {
        Object.assign(this, props);
    }

    get fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    public inChargeOf(activity: Activity): boolean {
        return this.id === activity.personInCharge?.firebaseUserId;
    }

    public clone(): Profile {
        const properties = { ...this };

        return new Profile(properties);
    }

    public toFakeUser(): User {
        const user = new User();

        user.id = this.userId;
        user.firebaseUserId = 'fake firebase user id';
        user.avatar = this.avatar;
        user.phoneNumber = this.phoneNumber;
        user.email = 'fake email';
        user.displayName = this.fullName;

        return user;
    }
}
