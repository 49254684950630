import { Observable } from 'rxjs';
import { isString } from '@webmozarts/types';

export interface HasTargetUrl {
    targetUrl: string;
}

export function hasTargetUrl(value: unknown): value is HasTargetUrl {
    return null !== value && isString(value['targetUrl']);
}

// This interface is not really necessary in the sense that we will only ever have one implementation.
// It however will help with keeping the methods signatures in sync.
export interface BaseMessagingService {
    getCurrentMessage(): Observable<unknown>;
    getNotificationClick(): Observable<HasTargetUrl|unknown>;
}

export interface MessagingPlatform extends BaseMessagingService {
    requestPermission(): Promise<NotificationPermission>;
    register(): Promise<boolean>;
    unregister(): Promise<boolean>;
    getToken(): Observable<string|null>;
}

export type NotificationPermission = 'granted' | 'denied';
