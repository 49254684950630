import { Component, Input } from '@angular/core';
import { SHARE_ACTIVITY_MESSAGE } from '@app/activities/activity-list/activity-list.page';
import { ActivityService } from '@app/core/services/activity/activity.service';
import { ShareService } from '@app/core/services/share/share.service';
import { DETAIL_ZOOM_LEVEL } from '@app/shared/components/abstract-map/abstract-map.component';
import { ShareOptionsComponent } from '@app/shared/components/share-options/share-options.component';
import { ActivityImageSize } from '@app/shared/models/activity-image.model';
import { Activity } from '@app/shared/models/activity.model';
import { Avatar, AvatarSize } from '@app/shared/models/avatar.model';
import { Profile } from '@app/shared/models/profile.model';
import { NavController, Platform, PopoverController } from '@ionic/angular';

@Component({
    selector: 'app-activity-card',
    templateUrl: './activity-card.component.html',
    styleUrls: ['./activity-card.component.scss'],
})
export class ActivityCardComponent {

    imageRatio: ActivityImageSize = window.devicePixelRatio > 1 ? '1000x750' : '500x375';

    @Input() activity: Activity;

    @Input() profile: Profile;

    @Input('margin') useMargin = true;

    @Input() alwaysShowImage = false;

    get showImage(): boolean {
        return this.activity?.featured || this.alwaysShowImage;
    }

    constructor(
        private popoverController: PopoverController,
        private shareService: ShareService,
        private navController: NavController,
        private platform: Platform,
        private activityService: ActivityService,
    ) {
    }

    getAvatar(avatar: Avatar): string {
        return avatar.get(AvatarSize.MEDIUM, AvatarSize.SMALL);
    }

    async onShareViaPopover(activity: Activity, event: Event) {
        event.preventDefault();
        event.stopPropagation();

        const popover = await this.popoverController.create({
            component: ShareOptionsComponent,
            componentProps: {
                url: this.activityService.generateActivityUrl(activity),
                message: SHARE_ACTIVITY_MESSAGE,
            },
            cssClass: 'auto-width',
            event,
            translucent: true,
        });

        popover.present();
    }

    onShareViaNativeDialog(activity: Activity, event: Event): void {
        event.preventDefault();
        event.stopPropagation();

        this.shareService.shareViaNativeDialog(
            this.activityService.generateActivityUrl(activity),
            SHARE_ACTIVITY_MESSAGE,
        );
    }

    onSelect(activity: Activity): void {
        // Early feedback
        this.activityService.setSelectedActivity(activity.id);

        const urlComponents = ['/activities/detail', activity.id];
        const queryParams = {
            ll: activity.place.latitude + ',' + activity.place.longitude,
            z: DETAIL_ZOOM_LEVEL,
        };

        if (this.platform.is('desktop')) {
            // On Desktop, use navigateRoot() so that the stack is completely
            // replaced. Otherwise navigating between activities on the map
            // confuses Ionic's stack. A proper solution would be to replace
            // the current page on the stack, but there's currently no
            // nicely working solution for that
            this.navController.navigateRoot(urlComponents, {
                queryParams,
                state: { canGoBack: true },
            });
        } else {
            urlComponents.push('mobile');

            this.navController.navigateForward(urlComponents, { queryParams });
        }
    }
}
