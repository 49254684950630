import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'tagAsSafeHtml' })
export class TagAsSafeHtmlPipe implements PipeTransform  {
    constructor(private sanitize: DomSanitizer) {
    }

    transform(value: string): SafeHtml {
        return this.sanitize.bypassSecurityTrustHtml(value);
    }
}
