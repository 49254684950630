import { Directive, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
    AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors,
} from '@angular/forms';
import { AddressDestinationService } from '@app/core/services/address-destination/address-destination.service';
import { EnvironmentService } from '@app/core/services/environment-service';
import {
    AddressDestination, PostalCodePatternByCountryCode,
} from '@app/shared/models/address-destination.model';
import { eq } from 'lodash-es';
import {
    from, MonoTypeOperatorFunction, Observable, of, OperatorFunction, ReplaySubject,
} from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';

interface Params {
    postalCode: string;

    countryCode: string;

    destinationName: string;
}

@Directive({
    selector: '[validDestinationName]',
    providers: [
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: forwardRef(() => ValidDestinationNameDirective),
            multi: true,
        },
    ],
})
export class ValidDestinationNameDirective implements OnChanges, AsyncValidator {

    @Input() postalCode: string;

    @Input() countryCode: string;

    private params = new ReplaySubject<Params>(1);

    private validity = from(this.params)
        .pipe(
            distinctUntilChanged((a, b) => eq(a, b)),
            debounceTime(500),
            this.mapCountryCodeValidation(),
            this.mapPostalCodeValidation(),
            this.validateAddress(),
        );

    private readonly validatedCountryCode: string;

    constructor(
        private addressDestinationService: AddressDestinationService,
        environmentService: EnvironmentService,
    ) {
        this.validatedCountryCode = environmentService.environment.validatedCountryCode;
    }

    private onValidatorChange = () => {
    };

    ngOnChanges(changes: SimpleChanges): void {
        this.onValidatorChange();
    }

    registerOnValidatorChange(fn: () => void): void {
        this.onValidatorChange = fn;
    }

    validate(control: AbstractControl): Observable<ValidationErrors | null> {
        this.params.next({
            countryCode: this.countryCode.trim(),
            postalCode: this.postalCode.trim(),
            destinationName: control.value.trim(),
        });

        return this.validity.pipe(first());
    }

    private mapCountryCodeValidation(): MonoTypeOperatorFunction<Params | null> {
        return map((params) => {
            if (this.validatedCountryCode !== params.countryCode) {
                return null;
            }

            return params;
        });
    }

    private mapPostalCodeValidation(): MonoTypeOperatorFunction<Params | null> {
        return map((params) => {
            if (!params) {
                return null;
            }

            const { postalCode } = params;
            const postalCodeRegex = new RegExp(PostalCodePatternByCountryCode[this.validatedCountryCode]);

            // we do not care about wrong postal codes in here
            // they are handled in valid-postal-code.directive
            if (!postalCode || !postalCode.match(postalCodeRegex)) {
                return null;
            }

            return params;
        });
    }

    private validateAddress(): OperatorFunction<Params | null, ValidationErrors | null> {
        return switchMap((params) => {
            if (!params) {
                return of(null);
            }

            const { countryCode, postalCode } = params;

            return this.addressDestinationService.findByCountryCodeAndPostalCode(
                countryCode,
                postalCode,
            )
                .pipe(
                    this.mapDestinationNameToErrorWithSuggestions(params),
                    first(),
                );
        });
    }

    private mapDestinationNameToErrorWithSuggestions(
        params: Params,
    ): OperatorFunction<AddressDestination[], ValidationErrors | null> {
        return map((destinations) => {
            if (destinations.some((destination) => destination.destinationName === params.destinationName)) {
                return null;
            }

            return {
                invalid: true,
                suggestions: destinations.map((destination) => destination.destinationName),
            };
        });
    }
}
