import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { AppApiHttpClient } from '@app/core/core.module';
import { SerializingHttpClient } from '@app/core/http/serializing-http-client';
import { AuthenticationService } from '@app/core/services/authentication/authentication.service';
import { Profile } from '@app/shared/models/profile.model';
import { FetchMode, useCache, useNetwork } from '@app/shared/utils/cache';
import { isNotNull } from '@webmozarts/types';
import { User } from 'firebase/auth';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, from, merge, Observable, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import { wrapError } from '@webmozarts/error';
import { HttpErrorResponse } from '@angular/common/http';

function isSameViewerById(viewerA: User | null, viewerB: User | null): boolean {
    return viewerA?.uid === viewerB?.uid;
}

@Injectable({
    providedIn: 'root',
})
export class ProfileService {

    private profileSource = new BehaviorSubject<Profile|null>(null);

    private ngUnsubscribe = new Subject<void>();

    constructor(
        @Inject(AppApiHttpClient) private http: SerializingHttpClient,
        private authenticationService: AuthenticationService,
        private errorHandler: ErrorHandler,
    ) {
        this.authenticationService.getViewer()
            .pipe(
                distinctUntilChanged(isSameViewerById),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe(() => this.profileSource.next(null));
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    createOrUpdateProfile(profile: Profile): Observable<Profile> {
        return this.http.put(Profile, '/viewer/profile', profile)
            .pipe(tap((_profile: Profile) => this.profileSource.next(_profile)));
    }

    syncProfileWithFirebase(): Observable<Profile> {
        const result = new Subject<Profile>();

        this.http.post(Profile, '/viewer/profile/sync/with/firebase', null)
            .pipe(tap((profile: Profile) => this.profileSource.next(profile)))
            .subscribe(result);

        return from(result);
    }

    getProfile(fetchMode: FetchMode = 'cache-first'): Observable<Profile> {
        const result = new Subject<Profile>();
        const profileFetched = null !== this.profileSource.getValue();

        if (!profileFetched || useNetwork(fetchMode)) {
            this.http.get(Profile, '/viewer/profile')
                .pipe(
                    catchError((error) => {
                        if (error instanceof HttpErrorResponse && 403 === error.status) {
                            this.errorHandler.handleError(
                                wrapError(
                                    'ProfileService',
                                    'Failed to fetch profile.',
                                    error,
                                ),
                            );

                            return this.authenticationService.signOut();
                        }

                        throw error;
                    }),
                    tap((profile: Profile) => this.profileSource.next(profile)),
                )
                .subscribe(result);
        } else {
            result.complete();
        }

        if (useCache(fetchMode)) {
            return merge(
                this.profileSource.pipe(filter(isNotNull)),
                result,
            )
                .pipe(distinctUntilChanged(isEqual));
        }

        return from(result);
    }

    updateAvatar(file: File): Observable<Profile> {
        const result = new Subject<Profile>();

        const options = {
            headers: {
                'Content-Type': file.type,
            },
            serialize: false,
        };

        this.http.put(Profile, '/viewer/profile/avatar', file, options)
            .pipe(tap((profile: Profile) => this.profileSource.next(profile)))
            .subscribe(result);

        return from(result);
    }

    deleteProfile(): Observable<void> {
        return this.http.delete(null, '/viewer/profile');
    }
}
