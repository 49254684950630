import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { CustomPlatformService } from '@app/core/services/custom-platform.service';
import { environment } from '@app/environments/environment';
import { FixedIonicRouteStrategy } from '@app/shared/routing/fixed-ionic-route-strategy';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { IonicModule, Platform } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';

import { CURRENT_YEAR_TOKEN } from '@app/app.config';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

registerLocaleData(localeDe, environment.language);
moment.locale(environment.language);

export function httpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/translations/', '.json');
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            // Use Material Design styles everywhere for now
            mode: 'md',
        }),
        AppRoutingModule,
        CoreModule,
        SharedModule,
        IonicStorageModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (httpLoaderFactory),
                deps: [HttpClient],
            },
        }),
    ],
    providers: [
        Clipboard,
        SocialSharing,
        Geolocation,
        CookieService,
        { provide: RouteReuseStrategy, useClass: FixedIonicRouteStrategy },
        { provide: LOCALE_ID, useValue: environment.language },
        { provide: Platform, useClass: CustomPlatformService },
        { provide: CURRENT_YEAR_TOKEN, useValue: null },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
