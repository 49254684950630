import { Injectable } from '@angular/core';
import { ProfileService } from '@app/core/services/profile/profile.service';
import { StorageService } from '@app/core/services/storage/storage.service';
import { firstValueFrom } from 'rxjs';

const STORAGE_KEY = 'deviceNotificationsEnabled';

const createStorageKey = (viewerId: string) => `${STORAGE_KEY}/${viewerId}`;

@Injectable({
    providedIn: 'root',
})
export class LegacyDeviceNotificationsEnabledSettingService {

    constructor(
        private storageService: StorageService,
        private profileService: ProfileService,
    ) {
    }

    public async migrateViewerSettings(viewerId: string): Promise<void> {
        const notificationsEnabled = await this.loadLegacySetting(viewerId);

        if (null !== notificationsEnabled) {
            await this.migrateLegacySetting(viewerId, notificationsEnabled);
        }
    }

    private async migrateLegacySetting(viewerId: string, notificationsEnabled: boolean): Promise<void> {
        await this.clearLegacySetting(viewerId);

        if (!notificationsEnabled) {
            await this.disableAktuelleNachrichtenChannel();
        }
    }

    private async disableAktuelleNachrichtenChannel(): Promise<void> {
        const profile = await firstValueFrom(this.profileService.getProfile());

        const updatedProfile = profile.clone();
        updatedProfile.notificationChannelIds = updatedProfile.notificationChannelIds
            .filter((channelId) => channelId !== '9de2d3df-2c41-486d-8fe5-2a59b48416f9'); // Channel id of "Aktuelle Nachrichten"

        await firstValueFrom(this.profileService.createOrUpdateProfile(updatedProfile));
    }

    private async loadLegacySetting(viewerId: string): Promise<boolean|null> {
        return this.storageService.get(createStorageKey(viewerId));
    }

    private async clearLegacySetting(viewerId: string): Promise<unknown> {
        return this.storageService.remove(createStorageKey(viewerId));
    }
}
