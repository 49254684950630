import { HttpClient } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { UrlSegment } from '@angular/router';
import { environment } from '@app/environments/environment';
import { CreateUserInput } from '@app/shared/models/create-user-input.model';
import { deferToZone } from '@app/shared/utils/deferToZone';
import { isNotNull } from '@webmozarts/types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Persistence } from '@firebase/auth';
import { NavController, Platform } from '@ionic/angular';
import { getApp } from 'firebase/app';
import {
    Auth,
    browserLocalPersistence,
    EmailAuthProvider,
    fetchSignInMethodsForEmail,
    indexedDBLocalPersistence,
    initializeAuth,
    onAuthStateChanged,
    reauthenticateWithCredential,
    sendEmailVerification,
    sendPasswordResetEmail,
    signInWithCustomToken,
    signInWithEmailAndPassword,
    updatePassword,
    User,
} from 'firebase/auth';
import { CookieService } from 'ngx-cookie-service';
import { defer, delayWhen, from, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { filter, map, share, tap } from 'rxjs/operators';

export enum LoginResult {
    SUCCESS,
    FAILED
}

export function generateFirebaseUserId(): string {
    return generateRandomHex(28);
}

function generateRandomHex(size: number): string {
    return [...Array(size)]
        .map(() => Math.floor(Math.random() * 16).toString(16))
        .join('');
}

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {

    private viewerSource = new ReplaySubject<User | null>(1);

    private viewer = from(this.viewerSource);

    private idToken = new ReplaySubject<string>(1);

    private willSignOutSource = new ReplaySubject<void>(1);

    readonly willSignOut: Observable<void> = this.willSignOutSource;

    private refreshToken =
        defer(() => this.api.currentUser.getIdToken(true))
            // If multiple parallel requests need a new token, only fetch it once
            .pipe(share());

    private api: Auth;

    private redirectUrlAfterSignin: string | UrlSegment[];

    constructor(
        private errorHandler: ErrorHandler,
        private navController: NavController,
        private http: HttpClient,
        private zone: NgZone,
        private cookieService: CookieService,
        private platform: Platform,
    ) {
    }

    initialize(): void {
        this.api = initializeAuth(
            getApp(),
            { persistence: this.getPersistence() },
        );
        this.api.languageCode = 'de';

        onAuthStateChanged(
            this.api,
            (user: User) => this.onAuthStateChanged(user),
            (e) => this.errorHandler.handleError(e),
        );

        this.api.onIdTokenChanged(
            (user: User) => this.onIdTokenChanged(user),
            (e) => this.errorHandler.handleError(e),
        );
    }

    createUser(input: CreateUserInput): Observable<unknown> {
        return this.http.post(
            `${environment.appApiUrl}/viewer`,
            {
                firebaseUserId: generateFirebaseUserId(),
                ...input,
            },
        );
    }

    changePassword(password: string): Observable<User> {
        return deferToZone(this.zone, async () => {
            await updatePassword(this.api.currentUser, password);
            await this.api.currentUser.reload();

            this.viewerSource.next(this.api.currentUser);

            return this.api.currentUser;
        });
    }

    fetchSignInMethodsForEmail(email: string): Observable<string[]> {
        return deferToZone(this.zone, async () => fetchSignInMethodsForEmail(this.api, email));
    }

    sendPasswordResetEmail(email: string): Observable<void> {
        return deferToZone(this.zone, async () =>
            sendPasswordResetEmail(this.api, email, {
                url: environment.appUrl + '/login',
            }));
    }

    signIn(email: string, password: string): Observable<LoginResult> {
        return deferToZone(this.zone, async () => {
            try {
                await signInWithEmailAndPassword(this.api, email, password);

                return LoginResult.SUCCESS;
            } catch (e) {
                return LoginResult.FAILED;
            }
        });
    }

    signInWithCustomToken(customToken: string): Observable<LoginResult> {
        return deferToZone(this.zone, async () => {
            await this.api.setPersistence(browserLocalPersistence);

            try {
                await signInWithCustomToken(this.api, customToken);

                return LoginResult.SUCCESS;
            } catch (e) {
                return LoginResult.FAILED;
            }
        });
    }

    reauthenticate(password: string): Observable<LoginResult> {
        return deferToZone(this.zone, async () => {
            const email = this.api.currentUser.email;

            try {
                const credential = EmailAuthProvider.credential(email, password);

                await reauthenticateWithCredential(this.api.currentUser, credential);

                return LoginResult.SUCCESS;
            } catch (e) {
                return LoginResult.FAILED;
            }
        });
    }

    signOut(): Observable<void> {
        const result = new Subject<void>();

        this.willSignOutSource.next();

        deferToZone(this.zone, async () => this.api.signOut())
            .pipe(
                tap(() => {
                    // Ensure the cookie is really deleted
                    this.cookieService.delete('loggedin');
                    this.navController.navigateRoot(['/login']);
                },
                (e) => this.errorHandler.handleError(e)),
            )
            .subscribe(result);

        return from(result);
    }

    sendEmailVerification(): Observable<void> {
        return deferToZone(this.zone, async () =>
            sendEmailVerification(this.api.currentUser, {
                url: environment.appUrl + '/verify-email',
            }));
    }

    isAuthenticated(): Observable<boolean> {
        return this.viewer.pipe(
            map((viewer) => null !== viewer),
        );
    }

    isAuthenticatedAndEmailVerified(): Observable<boolean> {
        return this.viewer.pipe(
            map((viewer) => null !== viewer && viewer.emailVerified),
            // When the viewer gets authenticated we wait until the corresponding token is fetched as well before emitting.
            // Note that this is a temporary workaround for the issue described in https://app.asana.com/0/1204855870698384/1205369910595524/f.
            // Ideally this should be rolled back and the real underlying issue should be solved as
            // described in the referenced ticket.
            delayWhen((authenticated) => authenticated
                ? this.idToken.pipe(filter(isNotNull))
                : of(void 0)),
        );
    }

    getViewer(): Observable<User | null> {
        return this.viewer;
    }

    reloadViewer(): Observable<User> {
        return deferToZone(this.zone, async () => {
            await this.api.currentUser.reload();

            this.viewerSource.next(this.api.currentUser);

            return this.api.currentUser;
        });
    }

    getIdToken(): Observable<string> {
        return from(this.idToken);
    }

    refreshIdToken(): Observable<string> {
        return this.refreshToken;
    }

    private onIdTokenChanged(user: User) {
        if (user) {
            user.getIdToken()
                .then((idToken) => {
                    this.zone.run(() => this.idToken.next(idToken));
                })
                .catch((e) => {
                    this.zone.run(() => {
                        this.errorHandler.handleError(e);
                        this.idToken.next(null);
                        this.cookieService.delete('loggedin');
                    });
                });
        } else {
            this.zone.run(() => {
                this.idToken.next(null);
                this.cookieService.delete('loggedin');
            });
        }
    }

    private onAuthStateChanged(user: User) {
        this.zone.run(() => {
            if (user) {
                this.viewerSource.next(user);

                // Firebase stores authentication information in the local
                // storage, hence in the request, we do not have any information
                // whether a user is authenticated or not.
                // Store a cookie so that we can test already on the server
                // whether to present the static preview pages (if not logged
                // in, for Facebook, Twitter and visitors) or the actual
                // application (for logged in users)
                this.cookieService.set('loggedin', 'yes', null, '/');
            } else {
                this.viewerSource.next(null);
                this.cookieService.delete('loggedin');
            }
        });
    }

    getRedirectUrlAfterSignin(): string | any[] {
        return this.redirectUrlAfterSignin;
    }

    hasRedirectUrlAfterSignin(): boolean {
        return !!this.redirectUrlAfterSignin;
    }

    setRedirectUrlAfterSignin(url: string | any[]): void {
        this.redirectUrlAfterSignin = url;
    }

    private getPersistence(): Persistence | Persistence[] {
        return this.platform.is('ios')
            ? indexedDBLocalPersistence
            : browserLocalPersistence;
    }
}
